
import http from '../axios'

// 商铺首页 详情页
export const getDetailHomeData = params => {
    return http.post('/shop/home', params)
}

// 商品页面的最新报价
export const detailOfferData = params => {
    return http.post('/shop/offers', params)
}

// 商品页面的贸易商机
export const detailNews = params => {
    return http.post('/shop/news', params)

}

// 商品页面的业务联系 
export const getUserData = params => {
    return http.post('/shop/user', params)

}

// 业务联系
export const LinkMeData = params => {
    return http.post('/shop/user', params)
}

// 点赞接口 
export const $zanApi = params => {
    return http.post('/shop/likes', params)
}
// 关注接口 
export const $gzApi = params => {
    return http.post('/shop/follow', params)
}