import {
    detailOfferData,
    detailNews
} from '@/api/modules/companyDetail_api.js'
import { $searchQuoteForInfo } from '@/api/modules/quote_api.js'

import {
    defineStore
} from 'pinia'


// 公司详情页的最新报价内容
export const usePriceStore = defineStore('detailPrice', {
    state: () => {
        return {
            quoteList: [], //最新报价模块显示的数据
            total: 0, // 总数
            loading: false, // loading
            homeOfferList: [],
            companyId: null, // 公司id
            // 搜索时用到的参数
            params: {
                limit: '20', // 单页条数
                type_id: '0', // 类型为0 查所有
                wide: '', //最大价格 	
                tiny: '', //最小价格
                name: '', // 品名
                spec: '', // 规格
                texture: '', //材质
                type: '', // 默认为全部
                regionId: '', // 地区
                user_id: '', //发布人
                info: '',  // 查询字段
            },
            flag: false, //最新报价点击更多时节流
        }
    },
    getters: {},
    actions: {
        // 根据info进行查询
        async searchHandlerForInfo() {
            this.loading = true
            let res = await $searchQuoteForInfo({
                id: this.companyId,
                ...this.params
            })
            let {
                status,
                data
            } = res
            if (status === 1) {
                const list = data.map(item => {
                    return {
                        action: 'more',
                        offers: item,
                        regions: item.company.regions,
                        name: item.company.name
                    }
                })
                this.quoteList = list // 最新报价模块中的数据
                this.total = data.total // 该关键词的总条数
                this.loading = false
                //  this.params.info = '' // 清空搜索关键词
            }
        },
        // 清空params的查询参数
        clearParams() {
            this.params.name = ''
            this.params.spec = ''
            this.params.texture = ''
            this.params.user_id = ''
            this.params.wide = ''
            this.params.tiny = ''
        },
        //初始化 主页的8条数据
        async initHomeOffrData(params) {
            sessionStorage.setItem('companyID', params.id)
            this.companyId = params.id || sessionStorage.getItem('companyID')
            this.loading = true
            let res = await detailOfferData({
                limit: 6,
                ...params
            })
            if (res.status === 1) {
                this.total = res.data
                this.homeOfferList = res.data
                this.loading = false
            }
        },
        // 初始化模块的最新报价数据
        async initOfferData(params) {
            this.companyId = sessionStorage.getItem('companyID')
            this.loading = true
            let res = await detailOfferData({
                limit: 20,
                id: this.companyId,
                ...this.params,
                ...params
            })
            if (res.status === 1) {
                this.total = res.data.total
                this.quoteList = res.data
                this.loading = false
            }
        },
        // 切换类型
        async checkType(params) {
            this.clearParams()
            this.params.type = params.type
            this.loading = true
            let res = await detailOfferData({
                limit: 20,
                id: this.companyId,
                ...this.params,
                ...params,
            })
            if (res.status === 1) {
                this.total = res.data.total
                this.quoteList = res.data
                this.loading = false
            }
        },


        // 点击更多报价
        async clickMore(params) {
            this.loading = true
            let {
                target
            } = params
            if (this.flag) {
                return
            }
            this.flag = true
            /*  params = {
                 company_id: 219,
                 type_id: 14,
                 limit: 3
             } */
            let res = await getMoreCompanyOffer(params)
            let {
                status,
                data
            } = res
            if (status === 1) {
                if (target === 'offer') {
                    this.quoteList = moreHandler(this.quoteList, data, params.company_id)
                } else {
                    this.homeOfferList = moreHandler(this.homeOfferList, data, params.company_id)
                }
                this.flag = false
                this.loading = false
            }
        },

        // 点击收起时触发
        clickPuck(row, target) {
            if (target == 'offer') {
                this.quoteList = this.quoteList.filter(item => {
                    if (item.id == row.id && item.isChildren) {
                        return false
                    } else {
                        if (item.id == row.id) {
                            item.action = 'more'
                        }
                        return true
                    }
                })

            } else {
                this.homeOfferList = this.homeOfferList.filter(item => {
                    if (item.id == row.id && item.isChildren) {
                        return false
                    } else {
                        if (item.id == row.id) {
                            item.action = 'more'
                        }
                        return true
                    }
                })

            }

        },
        // 初始化数据
        async initQuoteList(params, flag) {
            params.limit = 20 //一页展示20条数据
            // 如果已经发送过了 就不发送请求了
            if (this.quoteList.length == 0 || params.page == 1) {
                try {
                    this.loading = true
                    let res = await detailOfferData(params)
                    let {
                        status,
                        data
                    } = res
                    if (status === 1) {
                        const list = data.map(item => {
                            item.action = 'more'
                            return item
                        })
                        this.quoteList = list // 最新报价模块中的数据
                        this.total = data.total
                        this.loading = false
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            // 如果传了一个flag 强制发送请求 那么就再次发送请求
            if (!flag) return
            try {
                this.loading = true
                let res = await detailOfferData(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    const list = data.map(item => {
                        item.action = 'more'
                        return item
                    })
                    this.quoteList = list // 最新报价模块中的数据
                    this.total = data.total
                    this.loading = false
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 通过 品名 规则 材质 进行搜索
        async searchQuoteList(params) {

            params = {
                id: this.companyId,
                ...this.params,
                ...params
            }
            try {
                this.loading = true
                let res = await detailOfferData(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    const list = data.map(item => {
                        item.action = 'more'
                        return item
                    })
                    this.quoteList = list // 最新报价模块中的数据
                    this.total = data.total // 该关键词的总条数
                    this.loading = false
                }
            } catch (error) {
                console.log(error);
            }
        },
    }
})


// 公司的贸易商机
export const useNewsStore = defineStore('detailNews', {
    state: () => {
        return {
            newsList: [], // 贸易商机数组
            companyId: null, //公司id
            loading: false,
            mainNewsList: [], // 贸易商机板块的贸易商机
            total: 0, // 总数
            params: {
                regionId: '', // 地区id
                info: '', // 关键字
                /*    start: '', //开始时间
                   end: '', //结束时间 */
            }
        }
    },
    actions: {
        clearParams() {
            this.params.regionId = ''
            // this.params.info = ''
        },
        // 首页贸易商机的数据
        async initNewList(params) {
            sessionStorage.setItem('companyID', params.id)
            this.loading = true
            this.companyId = params.id // 保存公司id
            params = {
                limit: 6,
                ...params
            }
            let res = await detailNews(params)
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.newsList = data
                this.loading = false
                this.total = data.total
            }
        },
        // 模块 贸易商机的数据
        async initNewsListForMain(params) {
            this.companyId = sessionStorage.getItem('companyID')
            this.loading = true
            params = {
                limit: 20,
                id: this.companyId,
                ...params
            }
            let res = await detailNews(params)
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.mainNewsList = data
                this.loading = false
                this.total = data.total
            }
        },

        // 贸易上级切换tabs发送数据拿到数据
        async checkTabsData(params) {
            this.loading = true
            params = {
                limit: 20,
                id: this.companyId,
                ...this.params,
                ...params

            }
            let res = await detailNews(params)

            let {
                status,
                data
            } = res
            if (status == 1) {
                this.mainNewsList = data
                this.loading = false
                this.total = data.total
            }
        },

        async checkNewsCloumn(params) {
            params = params || {}
            this.loading = true
            params = {
                limit: 20,
                id: this.companyId,
                ...params,
                ...this.params
            }
            let res = await detailNews(params)
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.mainNewsList = data
                this.loading = false
                this.total = data.total
            }
        }
    }
})